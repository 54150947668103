
#emailtype, #emailbtn {
    height: 35px;
}

div.email {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: 'center';
}

#surprise {
    margin-top: 100px;
}

.admin_login form {
    margin-top: 50px;
}

.admin_login form label {
    font-size: x-large;
    display: inline-block;
    margin-bottom: 5px;
}

a:hover {
    color: #157c7b;
}

#lessonHistorySearch {
    display: flex;
}

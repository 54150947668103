@font-face {
    font-family: Ubuntu;
    src: url(Ubuntu.ttf);
}

@font-face {
    font-family: HelveticaBold;
    src: url(HelveticaBold.ttf);
}

@font-face {
    font-family: Helvetica;
    src: url(Helvetica.ttf);
}

html {
    background: linear-gradient(#05b2a1, white);
    color: white;
}

body, #root {
    margin: 0;
}

.body {
    font-family: Helvetica;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height:100vh;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
}

#main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

form {
    width: 250px;
}

h1 {
    margin-top: 35px;
    text-align: center;
}

h1, h2, h3 {
    font-family: Ubuntu;
}

#footer {
    margin-top: 20px;
    margin-bottom: 20px;
}

#logo {
    width: 300px;
}

.mobile #logo {
    width: 200px
}

input {
    font-size: 20px;
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    border-color: white;
}

input.submit,
input.button {
    color: #05b2a1;
    cursor: pointer;
    border-color: white;
    text-align: center;
}

ul {
    padding: 0;
    margin-top: 25px;
    list-style-type: none;
}

ul > li {
    color: #1D1D1D;
    font-size: 1.4em;
}

ul > li li {
    font-size: 1em;
    margin: 0;
}

ul > li ul {
    margin: 0;
}

.error {
    color: red;
}

a {
    color: white;
    text-decoration: none;
}

h2 a, h3 a {
    color: #157c7b;
}

h2 a:hover,
h3 a:hover {
    color: #1a9594;
}

button {
    color: white;
    background: transparent;
    border: none;
    font-size: 0.9em;
}

button:hover {
    color: #51cabe;
    cursor: pointer;
}

.modal {
	position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    background: url('../images/empty.gif') repeat;
    background-color: rgba(0, 0, 0, 0.5);
	cursor: default;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modal.transparent {
    background-color: transparent;
}

.modal .frame {
    background: rgba(41, 65, 63, 0.9);
    border-radius: 10px;
    box-shadow: 0 0 100px 2px #05b2a1;
    padding: 30px;
    margin: 15px;
    position: relative;
}

.modal .frame .close_button {
    position: absolute;
    width: 50px;
    height: 50px;
    right: -30px;
    top: -45px;
    background: url('../images/close.png');
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}


.modal .error {
    color: yellow;
}

.flexrow {
    display: flex;
    flex-direction: row;
}

#profile {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 150px;
    height: 150px;
    border-radius: 75px;
    border: 2px solid #157C7B;
    background: linear-gradient(#43dfaf, #38c2db);
    cursor: pointer;
}

#main.info {
    width: 800px;
}

#main.info .content {
    background-color: rgba(41, 65, 63, 0.5);
    padding: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
}

#forgottenPasswordLink {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-top: 5px;
    cursor: pointer;
}

/* RESET PASSWORD MODAL */

.modal .reset_password h3 {
    margin-top: 0;
}

.modal .reset_password .email_reset .email {
    font-size: medium;
}

.reset_password .email_reset .submit,
.reset_password .enter_code .submit
{
    margin-left: 10px;
    width: 150px;
}

.reset_password .email_reset input,
.reset_password .new_password input {
    width: 250px;
}

.reset_password .enter_code input {
    width: 100px;
}

.reset_password .submit_wrapper, 
.change_password .submit_wrapper
{
    width: 100%;
    margin-top: 30px;
}

.reset_password .new_password .submit, 
.change_password .submit
{
    display: block;
    margin: auto;
    width: 50%;
}

#Code {
    width: 120px;
}

.new_password h3 {
    margin-bottom: 5px;
}

.new_password .rules {
    width: 257px;
    font-size: smaller;
    margin-bottom: 10px
}

.new_password .error {
    margin-top: 5px;
}

/* CHANGE PASSWORD MODAL */

.modal.password.change .frame {
    min-width: 260px;
}

.modal.password.change h3 {
    margin-top: 0;
}

.modal.password.change h4.new {
    margin-bottom: 0;
}

.mytoast {
    --toastify-color-light: linear-gradient(to right, rgb(255, 95, 109), rgb(255, 195, 113));
    --toastify-text-color-light: white;
}

.Toastify__toast-body {
    text-align: center;
  }

/*** Small screens ***/

@media only screen and (max-width: 880px) {

    #profile {
        position: relative;
        left: 0;
        top: 8px;
    }
}

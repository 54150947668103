


#profileMenu .frame {
    float: left;
    position: absolute;
    left: 200px;
    top: 0;
    text-align: center;
    padding-top: 18px;
    padding-bottom: 18px;
}

#profileMenu li + li:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

li .creditsCount {
    font-family: HelveticaBold;
    color: #376f89;
}

li .creditsCount.creditor {
    color: green
}

li .creditsCount.debtor {
    color: #ad0b0b
}

#debts {
    margin-top: 25px;
    text-align: center;
}

#debtsUL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 10px;
}

#debts li.debt {
    width: 480px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.25);
    margin-bottom: 15px;
    border-radius: 20px;
}

#lessonsHistory h1 {
    margin-bottom: 50px;
}

#lessonsHistoryUL {
    text-align: center;
}

#lessonsHistoryUL li.lesson + li::before {
    content: '';
    border-top: 1px solid black;
    width: 100px;
    display: block;
    margin: auto;
}

#lessonsHistoryUL li.lesson ul {
    margin: 20px;
}

.mobile #debts li {
    width: 100%;
}

@media only screen and (max-width: 880px) {
    #profileMenu .frame {
        position: relative;
        top: 0;
        left: 0;
    }
}
